(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("beautifier", [], factory);
	else if(typeof exports === 'object')
		exports["beautifier"] = factory();
	else
		root["beautifier"] = factory();
})(typeof self !== 'undefined' ? self : typeof windows !== 'undefined' ? window : typeof global !== 'undefined' ? global : this, function() {
return 